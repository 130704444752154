import React from "react";
import { Modal } from "react-bootstrap";
// import Group from "../FormsElements/Group";
import Button from "../Button";
const ConformationModel = (props) => {
  return (
    <div>
      <Modal
        show={props.modelStatus}
        onHide={() => {
          props.handleClose();
        }}
        className={`EditNftModel`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.description}
          <div className={`d-flex justify-content-end mt-3`}>
            <Button
              className={`mr-2`}
              onClick={() => {
                props.handleOnSubmit();
              }}
            >
              Confirm
            </Button>
            <Button
              className={``}
              varient={"secondary_nooutline"}
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConformationModel;
